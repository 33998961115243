var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registration wrapper auth-layout"},[_c('div',{staticClass:"registration-form"},[_vm._m(0),_c('div',{staticClass:"form-wrapper"},[_c('h1',{staticClass:"form-title"},[_vm._v("Вхід")]),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.invalid.email & _vm.invalid.password)?_c('p',{staticClass:"form__input_error-text"},[_vm._v(" Адреса електронної пошти або пароль не співпадають ")]):_vm._e(),(_vm.limitLogin)?_c('p',{staticClass:"form__input_error-text"},[_vm._v(" "+_vm._s(_vm.limitLogin)+" ")]):_vm._e(),_c('div',{staticClass:"form-input-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form__input form__input_primary",class:{ 'is-invalid': _vm.invalid.email },attrs:{"disabled":_vm.codeSent,"placeholder":"Логін","name":"login","required":"","type":"email","autocomplete":"off"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.codeSent),expression:"!codeSent"}],staticClass:"form-input-container form-input-container__password"},[((_vm.passwordFieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form__input form__input_primary form__input-password",class:{
              'is-invalid': _vm.invalid.password,
            },attrs:{"placeholder":"Пароль","required":"","autocomplete":"off","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.password)?_vm._i(_vm.form.password,null)>-1:(_vm.form.password)},on:{"change":function($event){var $$a=_vm.form.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "password", $$c)}}}}):((_vm.passwordFieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form__input form__input_primary form__input-password",class:{
              'is-invalid': _vm.invalid.password,
            },attrs:{"placeholder":"Пароль","required":"","autocomplete":"off","type":"radio"},domProps:{"checked":_vm._q(_vm.form.password,null)},on:{"change":function($event){return _vm.$set(_vm.form, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form__input form__input_primary form__input-password",class:{
              'is-invalid': _vm.invalid.password,
            },attrs:{"placeholder":"Пароль","required":"","autocomplete":"off","type":_vm.passwordFieldType},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',{staticClass:"password-icon",class:{
              'form-input-container__password_open': _vm.showPassword,
              'form-input-container__password_close': !_vm.showPassword,
            },attrs:{"type":"password"},on:{"click":_vm.switchVisibility}}),_c('p',{staticClass:"form__input_note-text"},[_c('b-link',{attrs:{"to":{ name: 'ForgotPassword' }}},[_vm._v(" Забули пароль? ")])],1)]),_c('div',{staticClass:"form-input-container"},[(_vm.codeSent)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.code),expression:"form.code"}],staticClass:"form__input form__input_primary",class:{
              'is-invalid': _vm.invalid.code,
            },attrs:{"placeholder":"Код авторизації","type":"text","autocomplete":"off"},domProps:{"value":(_vm.form.code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "code", $event.target.value)}}}):_vm._e()]),(_vm.invalid.code && !_vm.invalid.email && !_vm.invalid.password)?_c('p',{staticClass:"form__input_error-text"},[_vm._v(" Помилка у коді авторизації ")]):_vm._e(),(_vm.limit2Fa)?_c('p',{staticClass:"form__input_error-text"},[_vm._v(" "+_vm._s(_vm.limit2Fa)+" ")]):_vm._e(),(_vm.codeSent)?_c('button',{staticClass:"form__button",attrs:{"type":"button","disabled":_vm.codeResend},on:{"click":_vm.sendCode}},[_vm._v(" Перевислати код авторизації ")]):_vm._e(),_c('button',{staticClass:"form__button",attrs:{"type":"submit","disabled":_vm.submitting}},[_vm._v(" Увійти ")]),_c('div',{staticClass:"form__link"},[_vm._v(" Ви ще не зареєстровані в AML.point? "),_c('b-link',{attrs:{"to":{ name: 'Register' }}},[_vm._v(" Зареєструватися ")])],1)])])]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aml-logo"},[_c('img',{attrs:{"src":"/images/aml-logo.svg","alt":"aml"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registration-video"},[_c('video',{staticClass:"aml-video",attrs:{"src":"/video/aml-register.mp4","autoplay":"","loop":""}})])
}]

export { render, staticRenderFns }