<template>
  <div class="registration wrapper auth-layout">
    <div class="registration-form">
      <div class="aml-logo"><img src="/images/aml-logo.svg" alt="aml" /></div>
      <div class="form-wrapper">
        <h1 class="form-title">Вхід</h1>
        <form class="form" @submit.prevent="onSubmit">
          <p
            class="form__input_error-text"
            v-if="invalid.email & invalid.password"
          >
            Адреса електронної пошти або пароль не співпадають
          </p>
          <p class="form__input_error-text" v-if="limitLogin">
            {{ limitLogin }}
          </p>
          <div class="form-input-container">
            <input
              :disabled="codeSent"
              placeholder="Логін"
              name="login"
              class="form__input form__input_primary"
              required
              type="email"
              v-model="form.email"
              :class="{ 'is-invalid': invalid.email }"
              autocomplete="off"
            />
          </div>
          <div
            v-show="!codeSent"
            class="form-input-container form-input-container__password"
          >
            <input
              placeholder="Пароль"
              :type="passwordFieldType"
              class="form__input form__input_primary form__input-password"
              required
              v-model="form.password"
              :class="{
                'is-invalid': invalid.password,
              }"
              autocomplete="off"
            />
            <div
              class="password-icon"
              type="password"
              @click="switchVisibility"
              :class="{
                'form-input-container__password_open': showPassword,
                'form-input-container__password_close': !showPassword,
              }"
            ></div>
            <p class="form__input_note-text">
              <b-link :to="{ name: 'ForgotPassword' }"> Забули пароль? </b-link>
            </p>
          </div>

          <div class="form-input-container">
            <input
              v-if="codeSent"
              placeholder="Код авторизації"
              type="text"
              class="form__input form__input_primary"
              v-model="form.code"
              :class="{
                'is-invalid': invalid.code,
              }"
              autocomplete="off"
            />
          </div>
          <p
            class="form__input_error-text"
            v-if="invalid.code && !invalid.email && !invalid.password"
          >
            Помилка у коді авторизації
          </p>
          <p class="form__input_error-text" v-if="limit2Fa">
            {{ limit2Fa }}
          </p>

          <button
            v-if="codeSent"
            type="button"
            :disabled="codeResend"
            class="form__button"
            @click="sendCode"
          >
            Перевислати код авторизації
          </button>

          <button type="submit" :disabled="submitting" class="form__button">
            Увійти
          </button>
          <div class="form__link">
            Ви ще не зареєстровані в AML.point?
            <b-link :to="{ name: 'Register' }"> Зареєструватися </b-link>
          </div>
        </form>
      </div>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";

const WAITE_TIME = 60; //1 min

export default {
  name: "Login",
  data: () => ({
    showPassword: false,
    codeSent: false,
    passwordFieldType: "password",
    submitting: false,
    codeResend: true,
    timerCount: WAITE_TIME,
    limitLogin: false,
    limit2Fa: false,
    invalid: {
      email: false,
      password: false,
      code: false,
    },
    form: {
      email: "",
      password: "",
      code: "",
    },
  }),
  methods: {
    async onSubmit() {
      if (!this.codeSent && this.codeResend) {
        this.sendCode();
      } else {
        this.switchSubmitting();
        this.switchInvalidFields(false);
        await api
          .twoFaLoginUser(this.form)
          .then((response) => {
            this.$store.dispatch("auth/saveToken", {
              token: response.data.success,
            });
            this.switchSubmitting();
            this.$router
              .push({
                redirect: (window.location.href =
                  this.$route.query.redirect || window.location.origin),
              })
              .catch(() => {});
          })
          .catch(({ response }) => {
            this.switchSubmitting();
            if (response.status === 429) {
              this.switchInvalidFields(false);
              this.limit2Fa = response.data.description;
            } else {
              this.limit2Fa = false;
              this.invalid.code = true;
            }
          });
      }
    },
    async sendCode() {
      this.switchSubmitting();
      this.switchInvalidFields(false);
      this.setTimer();
      await api
        .loginUser(this.form)
        .then(() => {
          this.switchSubmitting();
          this.codeSent = true;
          this.countDownTimer();
          this.limitLogin = false;
        })
        .catch(({ response }) => {
          this.switchSubmitting();
          if (response.status === 403) {
            this.$router.push({
              name: "ResetExpiredPassword",
              query: { email: this.form.email },
            });
          } else if (response.status === 429) {
            this.switchInvalidFields(false);
            this.limitLogin = response.data.description;
          } else {
            this.limitLogin = false;
            this.switchInvalidFields();
          }
        });
    },
    switchSubmitting() {
      this.submitting = !this.submitting;
    },
    switchInvalidFields(value = true) {
      this.invalid.email = value;
      this.invalid.password = value;
      this.invalid.code = value;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.showPassword = !this.showPassword;
    },
    setTimer() {
      this.timerCount = WAITE_TIME;
      this.codeResend = true;
    },
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.codeResend = false;
      }
    },
  },
};
</script>

<style>
body,
p {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova";
}
* {
  transition: all 0.2s ease;
  box-sizing: border-box;
  color: inherit;
  text-decoration: none;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Light.ttf);
  font-weight: 300;
}
</style>
